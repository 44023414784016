$blue: #66c5ed; //主颜色，作业页面作业标题颜色，边框颜色
$dark-blue: #60b3e0; //反转色，用于鼠标悬浮时颜色变深等
$red: #e5397b;
$light-blue: #a2e3f2; //某些粗边框，隔断的颜色，
$grey: #f4f4f4; //某些大块的背景色
$border-grey: #c9caca; //边框颜色
$border-grey2: #efefef; //成员页面边框颜色
$border-blue: #8cc2f2; //作业列表边框颜色,删除作业x颜色
$green: #36cebb; //"已批阅"字体颜色
$dark-grey: #606060; //"未批阅"字体颜色，作业描述字体颜色
$red2: #e03885; //"未提交"字体颜色
$font-black: #47515b; //作业截止日期字体颜色
$font-grey: #727171; //字体颜色，左侧边栏未选中状态
$font-dark-grey: #3e3a39; //字体颜色,左侧边栏选中状态
$danger: #f72662; //删除等按钮颜色
$red3: #e51749; //成员页面"未确认"字体颜色
$female: #ea8068; //female图标颜色
